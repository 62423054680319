<template>
  <div class="Page">
    <el-alert v-if="errorMsg"
      :title="errorMsg"
      type="error"
      effect="dark">
    </el-alert>
    <el-alert v-if="successMsg"
      :title="successMsg"
      type="success"
      effect="dark">
    </el-alert>
    <div class="opt-bar flex">
      <el-button @click="handleNewRule" size="small" v-ripple>新增回复</el-button>
      <el-input size="small"
          @keyup.enter.native="onCurrentPageChange(1)"
          class="searcher-input"
          placeholder="关键词"
          clearable
          @clear="onSkeyClear"
          v-model="skey">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      <el-button @click="onCurrentPageChange(1)" type="primary" size="small"><i class="iconfont">&#xe622;</i></el-button>
    </div>

    <el-table :data="page.list" element-loading-text="Loading..."
      size="small"
      border fit highlight-current-row
      header-row-class-name="tableHeader"
      :height="tableHeight"
      :row-class-name="tableRowClass"
      row-key="id"
      default-expand-all
    >
      <el-table-column fixed align="center" label="Id" width="95">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="关键词" width="200" align="left">
        <template slot-scope="scope">
          {{ scope.row.rkey }}
        </template>
      </el-table-column>
      <el-table-column label="模糊" width="100" align="left">
        <template slot-scope="scope">
          {{ transBlur(scope.row.rkeyblur)}}
        </template>
      </el-table-column>
      <el-table-column label="类型" width="100" align="left">
        <template slot-scope="scope">
          {{ scope.row.retype}}
        </template>
      </el-table-column>
      <el-table-column label="全局" width="70" align="center">
        <template slot-scope="scope">
          {{ scope.row.isall==1?'全局':'否'}}
        </template>
      </el-table-column>
      <el-table-column label="启用" width="70" align="left">
        <template slot-scope="scope">
          {{  scope.row.enable==1?'启用':'禁用'}}
        </template>
      </el-table-column>
      <el-table-column label="回复内容"  align="left" class="hidden-md-and-down">
        <template slot-scope="scope">
          <div class="content">
            <label v-if="scope.row.retype == 'text'">{{scope.row.retext}}</label>
            <img v-if="scope.row.retype == 'image'" :src="scope.row.url"> 
            <div class="ctx-voice" v-if="scope.row.retype == 'voice'">
              <img class="ctx-icon" @click="handleCtxPlay(scope.row)" 
                :src="(ctxPlayer && ctxPlayer.playing && ctxPlayer.id == scope.row.id) ? require('@/assets/img/voice-gif.gif') : require('@/assets/img/voice.png')">
            </div> 
            <div class="ctx-video" v-if="scope.row.retype == 'video'">
              <img class="ctx-icon"  src="@/assets/img/video.png">
            </div>  
            <div class="ctx-news" v-if="scope.row.retype == 'news'">
              <img class="ctx-icon" src="@/assets/img/news.png">
            </div>                     
          </div>                    
        </template>
      </el-table-column>
      <el-table-column label="添加时间"  align="left" class="hidden-md-and-down">
        <template slot-scope="scope">
          {{scope.row.atime}}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100" align="right" fixed="right">
        <template slot-scope="scope">
            <icon-btn @click="handleEdit(scope.$index, scope.row)"
            icon="iconedit1"
            type="warning"
            ></icon-btn>
            <icon-btn @click="handleDelete(scope.$index, scope.row)"
            icon="icondel1"
            type="error"
            ></icon-btn>
        </template>
      </el-table-column>
    </el-table>
    <div class="pager">
      <div class="pager-info">共 {{page.total}} 条 第 {{page.currentPage}}/{{page.pageCount}} 页</div>
      <el-pagination
        @size-change="onPageSizeChange"
        @current-change="onCurrentPageChange"
        :current-page="page.currentPage"
        :page-sizes="[20, 30, 40, 50]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total">
      </el-pagination>
      <div class="pager-time" v-if="queryTime > 0">查询{{queryTime}}毫秒</div>
    </div>
    <template v-if="ctxPlayer && ctxPlayer.retype == 'voice'">
      <audio ref="ctxAudio" :src="ctxPlayer.url" type="audio/mpeg"></audio>
    </template>
    <!--dialog-->
    <el-dialog
      v-dialog-drag
      :title="dialogTitle"
      width="55em"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true"
      >
      <el-form class="form"
        :model="mform"
        :label-position="labelPosition"
        :status-icon="true"
        :show-message="true"
        :rules="mRules"
        ref="mform"
        :label-width="formLabelWidth"
      >
        <el-form-item class="item-label" label="关键词"  prop="rkey">
          <el-input class="input" v-model="mform.rkey" autocomplete="off" ></el-input>
        </el-form-item>
        <el-form-item class="item-label" label="状态" prop="enable">
          <el-switch
            class="switch"
            v-model="mform.enable"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="启用"
            inactive-text="禁用">
          </el-switch>
        </el-form-item>
        <el-form-item class="item-label" label="转接客服" prop="isservice">
          <el-switch
            class="switch"
            v-model="mform.isservice"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="转客服"
            inactive-text="自动回复">
          </el-switch>
        </el-form-item>
        <el-form-item class="item-label" label="模糊匹配" prop="rkeyblur">
          <el-switch
            class="switch"
            v-model="mform.rkeyblur"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="模糊匹配"
            inactive-text="精确匹配">
          </el-switch>
        </el-form-item>
        <el-form-item class="item-label" label="全局回复" prop="isall">
          <el-switch
            class="switch"
            v-model="mform.isall"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="全局未匹配回复"
            inactive-text="关键词匹配">
          </el-switch>
        </el-form-item>
        <el-form-item class="item-label" label="选择类型" prop="isall">
          <el-radio-group v-model="mform.retype" @change="onRetypeChange">
            <template v-for="ob in retypes" >
              <el-radio class="radio-item" :key="ob.value" :label="ob.value">{{ob.name}}</el-radio>
            </template>
          </el-radio-group>
        </el-form-item>

        <el-form-item  class="item-label" label="回复内容" prop="retext">
          <div class="" v-if="mform.retype=='text'"><!--文本-->
            <el-input  class="input textarea" type="textarea" v-model="mform.retext"></el-input>
            <div class="retext-note">注：文本回复可以嵌入链接，如：&lt;a href="{{global.home}}"&gt;请点击~&lt;/a&gt;</div>
          </div>
          <div v-if="mform.retype=='image'" class="retype-image"><!--图片-->       
            <el-image v-if="mform.url"
              style="width: 100px; height: 100px;border:1px solid #cbcbcb; border-radius: 5px; margin: 5px;"
              :src="mform.url"
              fit="fit"></el-image>
            <!-- <div class="media-name">{{curMedia.name}}</div>             -->
            <div>
              <el-button size="small" @click="showMediaSelect(1)">选择素材</el-button>
            </div>
          </div>
          <div v-if="mform.retype=='voice'" class="retype-image"><!--声音-->                               
            <div class="media-name">{{curMedia.name}}</div>            
            <div>
              <el-button size="small" @click="showMediaSelect(1)">选择素材</el-button>
            </div>
          </div>
          <div v-if="mform.retype=='video'" class="retype-image"><!--视频-->        
            <el-image v-if="curMedia.cover_url"
              style="width: 100px; height: 100px;border:1px solid #cbcbcb; border-radius: 5px; margin: 5px;"
              :src="curMedia.cover_url"
              fit="fit"></el-image>                       
            <div class="media-name">{{curMedia.newsubcat}} {{curMedia.name}}</div>            
            <div>
              <el-button size="small" @click="showMediaSelect(1)">选择素材</el-button>
            </div>
          </div>
          <div v-if="mform.retype=='news'" class="retype-image"><!--图文-->     
            <div class="news-result" v-if="curMedia && curMedia.content">
              <div v-if="curMedia.content.news_item.length>1">多图文({{curMedia.content.news_item.length}})</div>            
              <div v-else>单图文</div>
              <el-image v-if="curMedia.content.news_item[0].thumb_url"
                class="news-img0"                
                :src="curMedia.content.news_item[0].thumb_url"
                fit="fit"></el-image>                       
              <div class="media-name">                
                {{curMedia.content.news_item[0].title}}
              </div>
            </div>   
            <div>
              <el-button size="small" @click="showMediaSelect(1)">选择素材</el-button>
            </div>
          </div>
        </el-form-item>
        <!-- <div class="note">                    
          如果为普通客服转接：填写“客服”，如需指定客服：填指定客服的帐号，如“test1@test.com”
        </div> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" v-ripple>取 消</el-button>
        <el-button type="primary" @click="submitForm('mform')" v-ripple>确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog v-dialog-drag      
      :close-on-click-modal="false"
      :title="'选择' + curReType.name + '素材'"
      :visible.sync="dlgMediaFlag"
      width="80%"
      >
      <div class="media-box" v-if="mediaPage.type == 'image'" v-loading="loadingFlag">
        <div :class="'media-item media-item_' + (ob.media_id == curMedia.media_id)" @click="onMediaSelectResult(ob)" v-for="ob in mediaPage.list" :key="ob.media_id">
          <div class="item-image">        
            <img class="item-img" :src="ob.url">            
          </div>
          <div class="item-name">{{ob.name}}</div>
        </div>        
      </div>
      <div class="media-box" v-if="mediaPage.type == 'voice'">
        <audio ref="audio" class="myaudio" :src="curMedia.url" loop="loop"></audio>
        <div :class="'media-item item-voice  media-item_' + (ob.media_id == curMedia.media_id)" @click="onMediaSelectResult(ob)" v-for="ob in mediaPage.list" :key="ob.media_id">          
          <div class="item-name">{{ob.name}}</div>
          <div class="item-date">{{transTxTime(ob.update_time)}}</div>
        </div> 
      </div>
      <div class="media-box" v-if="mediaPage.type == 'video'" v-loading="loadingFlag">
        <div :class="'media-item media-item_' + (ob.media_id == curMedia.media_id)" @click="onMediaSelectResult(ob)" v-for="ob in mediaPage.list" :key="ob.media_id">
          <div class="item-image">        
            <img class="item-img" :src="ob.cover_url">            
          </div>
          <div class="item-name">{{ob.name}}</div>
        </div>        
      </div>
      <div class="media-box" v-if="mediaPage.type == 'news'" v-loading="loadingFlag">
        <div :class="'item-news media-item_' + (ob.media_id == curMedia.media_id)" @click="onMediaSelectResult(ob)" v-for="ob in mediaPage.list" :key="ob.media_id">
          <div v-for="(ct, idx) in ob.content.news_item" :key="idx">
            <div class="item-image" v-if="idx == 0">        
              <img :class="'news-img news-img-fist_' + (idx==0)" v-if="ct.thumb_url" :src="ct.thumb_url">            
            </div>
            <div class="news-row">
              <div class="item-name">{{ct.title}}</div>
              <img class="item-icon" v-if="idx>0" :src="ct.thumb_url">
            </div>
          </div>                   
        </div>        
      </div>
      <div class="dlg-pager">
        <el-pagination          
          @current-change="onMediaCurrentPageChange"
          :current-page="mediaPage.currentPage"        
          :page-size="mediaPage.pageSize"
          layout="total, prev, pager, next"
          :total="mediaPage.total">
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dlgMediaFlag = false">取 消</el-button>
        <el-button type="primary" @click="onMediaSelectResult(curMedia)">确 定</el-button>
      </span>
    </el-dialog>
    <div v-if="refreshFlag"></div>
  </div>
</template>

<script>
import IconBtn from '../../components/btns/IconBtn'
export default {
  components: {    IconBtn  },
  data() {
    var checkRetext = (rule, value, callback)=>{
      if (this.mform.retext == '') {
        switch(this.mform.retype) {        
          case 'text':
            callback(new Error('请填写回复的文本内容~'))
            return;
          case 'image':
            callback(new Error('请选择要回复的图片素材~'))
            return;
          case 'voice':
            callback(new Error('请选择要回复的声音素材~'))
            return;
          case 'video':
            callback(new Error('请选择要回复的视频素材~'))
            return;
          case 'news':
            callback(new Error('请选择要回复的图文素材~'))
            return;
        }        
      }
      callback();
    };
    return {
      refreshFlag: false,
      loadingFlag: false,
      dlgMediaFlag: false,
      mediaPage: {type:'image', total: 0, pageSize: 20, currentPage: 1, pageCount: 1, list: []},
      curMedia: {url: ''}, //选择好的媒体
      errorMsg: '',
      successMsg: '',
      tableHeight: 560,
      skey: '',
      page: {pg: 1, list:[], total: 0, pageSize: 20, currentPage: 1, pageCount: 1},
      queryTime: 0,

      //dialog
      dialogFormVisible: false,
      dialogTitle: '编辑回复规则',
      labelPosition: 'right',
      formLabelWidth: '120px',
      ctxPlayer: false,
      retypes: [
        {name: '文本', value: 'text'},        
        {name: '图片', value: 'image'},
        {name: '语音', value: 'voice'},
        {name: '视频', value: 'video'},        
        {name: '图文', value: 'news'},
      ],
      curReType: false,
      mform : {
        rkey: '',
        enable: 1,
        isservice: 0,
        rkeyblur: 0,
        isall: 0,
        retype: 'text',
        retext: '',
        url: '',
      },
      mRules: {
        rkey: [
          { required: true, message: '请输入关键词', trigger: 'blur' },
          { min: 1, max: 5, message: '长度在 1 到 30 个字符', trigger: 'blur' }
        ],
        retext: [
          { required: true, message: '请输入或选择回复内容', trigger: 'blur' },
          { validator: checkRetext, trigger: 'blur' },           
        ],
      },
    };
  },
  created(opt) {
    this.curReType = this.retypes[0]
    this.resize();
    window.onresize = this.resize();
    this.onCurrentPageChange(1)
  },
  methods: {  
    showMediaSelect(pg) {
      this.dlgMediaFlag = true
      this.nprogress.start();
      var pageSize = this.mform.retype == 'news' ? 10 : 18
      var pm = {
        type: this.mform.retype,
        pg,
        pageSize,
      }      
      this.loadingFlag = true
      this.post('/api/media/batchget_material', pm, res=>{       
        this.loadingFlag = false
        console.log(res)
        if (res.code == 0) {
          this.mediaPage = res.data
          this.mediaPage.type = res.type;
        } else {
          this.$notify.error(res.msg)
        }
      })
    },
    onMediaCurrentPageChange(pg) {
      this.showMediaSelect(pg)
    },
    onMediaSelectResult(ob) {
      if (!ob) {
        this.toast('请选择媒体素材~')
        return;
      }
      this.curMedia = ob
      this.mform.retext = ob.media_id
      this.mform.url = ob.url
      this.dlgMediaFlag = false;
    },
    voicePlay(ob) {
      if (this.curMedia) this.curMedia.playing = false;
      this.curMedia = ob;
      this.$refs.audio.play();
    },
    handleCtxPlay(row) { //表格中的行的播放    
      this.toast('暂不支持播放~') 
      return;
      this.ctxPlayer = row
      var pm = {

        media_id: row.retext
      }
      row.playing = !row.playing; 
      if (!row.playing) {
        var el = this.$refs['ctxAudio'];
        if (el) el.pause();
        return;
      }
      this.post('api/media/get_material', pm, res=>{
        if (res.code == 0) {
          console.log(res.data.url)
          var el = this.$refs['ctxAudio'];
          el.src = res.url
          el.play();
        } else {
          this.$notify.error(res.msg)
        }
        console.log(res)
      })

    },
    handleDelete(idx, row) {

    },
    handleEdit(idx, row) {
      this.mform = row;
      this.dialogFormVisible = true
      if (row.mtype != 'text') { //需要取出
        
      } else {

      }
    },
    handleNewRule() {
      this.mform = {
        rkey: '',
        enable: 1,
        isservice: 0,
        rkeyblur: 0,
        isall: 0,
        retype: 'text',
        retext: '',
        url: '',
      }
      this.dialogFormVisible = true
      // this.$refs.mform.resetFields();
    },
    onSkeyClear() {
      this.onCurrentPageChange(1)
    },
    resize() {
      var clientHeight = document.documentElement.clientHeight
      this.tableHeight = clientHeight - 160
    },
    onSkeyClear() {
      this.onCurrentPageChange(1)
    },
    async onCurrentPageChange(pg){
      var pm = {
        pg : pg,
        pageSize: this.page.pageSize,
        skey: this.skey        
      }
      this.post('api/me/rules', pm, res=>{
        if (res.code != 0) {
          this.$message.error('出错了~' + res.msg);
          return
        }
        this.queryTime = res.pageTime
        this.page = res.data
      })
    },
    onPageSizeChange(e) {
      this.page.pageSize = e;
      this.onCurrentPageChange(1)
    },
    transStat(stat) {
      var res = ''
      switch(stat) {
        case 0:
          res = '未启用'
          break;
        case 1:
          res = '已启用'
          break;
      }
      return res
    },
    transBlur(rkeyblur) {
      var res = ''
      switch(rkeyblur) {
        case 0:
          return '否'
        case 1:
          return '是'
      }
      return 'U'
    },
    tableRowClass({row, rowIndex}) {
      if (row.upid == 0) {
        return 'success-row'
      }
      if (row.enable === 0) {
        return "error-row"
      } else {
        if (rowIndex % 2 === 0) {
          return '';
        } else {
          return 'second-row'
        }
      }
    },
    onRetypeChange(e) {
      this.curReType = this.util.find(this.retypes, e, 'value');
      this.curMedia = false
      this.$refs.mform.clearValidate();
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid)=>{
        if (valid) {                  
          this.mform.platid = this.global.plat.id  
          this.post('/api/me/ruleSave', this.mform, res=>{
            if (res.code == 0) {
              var rule = res.data;
              if (!this.mform.id) {
                this.page.list.splice(0, 0, rule);
              } else {
                var idx = this.util.indexOf(this.page.list, rule);
                if (idx > -1) {
                  this.page.list.splice(idx, 1, rule);
                }                
              }
              this.$notify.success('保存成功~');
              this.dialogFormVisible = false
            } else {
              this.$notify.error(res.msg)
            }
          })
        } else {
          this.$notify.error('请填写完整~')
        }
      })
    },
    transTxTime(dt) {
      var val = new Date(dt * 1000);
      return this.util.formatDate(val)
    },
    refresh() {
      this.refreshFlag = !this.refreshFlag;
      this.$nextTick(()=>{
        this.refreshFlag = !this.refreshFlag;
      })
    },
  }//methods
};
</script>

<style scoped lang="scss">
.Page {
  margin-top: 0px;

  .form{
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .item-label{
      text-align: left;
      margin-top: 3px;
    }

    .input{
      width: 20em;
      text-align: left;
    }

    .input-icon{
      width: 10em;
    }
    .textarea{
      margin-left: 5px;
    }
    .switch{
      text-align: left;
      margin-top: 5px;
      width: 20em;
    }
    .radio-item {
      margin-top: 10px;
    }
    .retext-note {      
      text-align: left;
      color: gray;
      font-size: 0.9em;      
    }    
  }
}
.retype-image {
  display: flex;
}

.media-box {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}

.media-item {
  box-shadow: 0 0 3px gray;
  border-radius: 5px;
  margin: 5px;
  background-color: #cbcbcb;
  cursor: pointer;

  .item-name {
    margin: auto;
    width: 110px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
}


.item-image {
  vertical-align: middle;
  height: 130px;

  .item-img {
    width:120px;
    max-width: 120px;
    max-height: 120px;    
    vertical-align: middle;
    padding: 5px;
  }
}
.item-voice {
  background-color: #f8f8f8;
  width: 180px;
  height: 50px;
  padding-top: 0.6em;
  border-radius: 5px;
  box-shadow: 0 0 3px gray;
  margin: 5px;
}

.item-news {
   background-color: #f8f8f8;
  width: 210px;
  height: 210px;
  overflow-y: auto;
  padding-top: 0.6em;
  border-radius: 5px;
  box-shadow: 0 0 3px gray;
  margin: 5px;
}
.news-row {
  display: flex;
  margin-bottom: 0.5em;

  .item-name {
    flex: 1 1 auto;
    text-align: left;
    margin-left: 0.5em;
  }

  .item-icon {
    width: 32px;
    height: 32px;
  }
}

.news-img-fist_true {
  width: 190px;
}

.media-item:hover{
  background-color: $warning;  
  color: #fff;
}
.media-item_true {
  box-shadow: 0 0 3px black;
  background-color: $primary;
  color: #fff;
}
.media-name {
  margin: 5px;
}
.item-news {
  cursor: pointer;
}
.item-news:hover {
  background-color: $warning;  
  color: #fff;
}
.item-item_true {
  box-shadow: 0 0 3px black;
  background-color: $primary;
  color: #fff;
}
.news-result {
  margin-right: 2em;
  border: 1px solid #cbcbcb;
  border-radius: 0.5em;
  padding: 0.1em;
  .news-img0 {
    width: 160px;
    height: 60px;
  }
}
.dlg-pager {
  margin-top: 1em;
  margin-bottom: -2em;
}
.content {
  img {
    height: 60px;
    cursor: pointer;
  }
  .ctx-icon {
    width: 3em;
    height: 3em;
    cursor: pointer;
  }
}
</style>
